<template>
  <div class="resize">
    <div class="l-col">
      <base-input
        v-model="formData.name"
        :placeholder="$t('name.placeholder')"
        :label="$t('name.label')"
        class="form__field--input"
        @input="onChange"
      >
      </base-input>
    </div>
    <div class="l-col">
      <!--      <label class="typo__label">{{ $t('description.label') }}</label>-->
      <base-input
        v-model="formData.description"
        :label="$t('description.label')"
        :placeholder="$t('description.placeholder')"
        class="form__field--input"
        @input="onChange"
      >
      </base-input>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'NewFirewall',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    // instance: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data() {
    return {
      formData: {
        name: '',
        description: '',
      },
    };
  },
  computed: {
    // net() {
    //   return this.instance.map(x => x.name);
    // },
  },
  mounted() {
    this.$emit('notready');
  },
  methods: {
    // netId(name) {
    //   return name ? this.instance.find(x => x.name === name).id : null;
    // },
    onChange(event) {
      Object.assign(this.formData, event);
      if (this.formData.name) {
        return this.$emit('change', {
          security_group: {
            name: this.formData.name,
            description: this.formData.description,
          },
        });
      } else return this.$emit('notready');
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "name": {
      "placeholder": "Название файрвола",
      "label": "Файрвол:"

    },
    "description": {
      "placeholder": "Описание",
      "label": "Описание:"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
